import React, { Component } from 'react'
// import _ from 'lodash'

import {
    Row
} from 'react-bootstrap'
import { withNamespaces } from 'react-i18next'

import LocalImage from '../../components/local-image'

import '../../css/places-list.css'

class PlacesList extends Component {
    // constructor(props) {
    //     super(props)
    //     // Don't call this.setState() here!
    // }

    // componentWillReceiveProps(nextProps) {
    //     // DEBUG
    //     // console.log(nextProps)
    // }

    render() {
        // const self = this
        const t = this.props.t
        const lang = this.props.lng
        const placesList = this.props.placeslist

        return (
            <Row className="plist d-flex justify-content-start flex-wrap">
            {
                placesList.map((item,i) => {
                    let hs = (i<this.props.pts) ? ' show ' : 'hidden'
                    let slug = (item.NameEn)? (item.NameEn).match(/[A-Za-z0-9]+/g).join('_').toLowerCase() : item.PlaceID
                    return (
                        <a href={`/${lang}/map/places/${slug}`} key={`place-l-${i}`} className={`col-12 col-md-6 col-lg-3 ${hs}`} rel="noopener ">
                            <div className="plist-img text-center">
                                {
                                    ( item.typeobj === undefined ) ? null :
                                        <LocalImage name={item.typeobj.IconImage.split('.')[0]} attr={{className: "img-fluid", alt:"Factions"}} />
                                }
                            </div>
                            <div className="plist-box">
                                {t(item.Name)}
                            </div>
                        </a>
                    )
                })
            }
            </Row>
        )
    }
}

export default withNamespaces()(PlacesList)
