// const _ = require('lodash')

const { getAxios } = require('./get-axios-handler')
const dbset = require('./tablelist.json')

exports.getAxiosPlaceType = ( dbargs ) => {

    return new Promise(resolve => {

        getAxios( process.env.MDAPI_URL , { spreadsheetid:process.env.MDAPI_SHEET_ID, ...dbset.PlaceType, ...dbargs }).then( list => {
            
            resolve(list)
        })

    })

}
